<template>
  <div>
    <div class="flex">
      <p class="font-barlow font-medium text-xl text-grey-medium-2">
        {{ title }} {{ displayVersionForSeller(productVersion) }} /
      </p>
      <p class="font-barlow font-bold text-xl text-grey-dark-2 ml-1">
        Summary
      </p>
    </div>
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
      <p class="font-barlow font-bold text-mdl text-grey-light mb-2">
        Assessment Settings 
      </p>
      <div class="border border-b-0 border-grey-light-1 mb-3" />
      <Step1 class="px-2" viewOnly/>
      <div class="border border-b-0 border-grey-light-1 mb-3" />
      <Step2 class="px-2" viewOnly/>
      <div
        v-if="assessmentType === 0"
        class="border border-b-0 border-grey-light-1 mb-3"
      />
      <Step3 v-if="assessmentType === 0" class="px-2" viewOnly/>
      <div class="border border-b-0 border-grey-light-1 mb-3" />
      <Step4 class="px-2" viewOnly/>
      <div class="border border-b-0 border-grey-light-1 mb-3" />
      <Step6 class="px-2" viewOnly/>
      <div class="border border-b-0 border-grey-light-1 mb-3" />
      <Step7 class="px-2" viewOnly/>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button/Button.vue";
import Tooltip from "../../../components/Tooltip/Tooltip.vue";
import arrow from "@/assets/img/icons/arrow-right.svg";
import { mapState, mapActions, mapGetters } from "vuex";
import Step1 from "@/views/AdminAssessments/components/Summary/Step1.vue";
import Step2 from "@/views/AdminAssessments/components/Summary/Step2.vue";
import Step3 from "@/views/AdminAssessments/components/Summary/Step3.vue";
import Step4 from "@/views/AdminAssessments/components/Summary/Step4.vue";
import Step6 from "@/views/AdminAssessments/components/Summary/Step6.vue";
import Step7 from "@/views/AdminAssessments/components/Summary/Step7.vue";

export default {
  name: "OldAssessmentConfigurationSummary",
  components: { Button, Step1, Step2, Step3, Step4, Step6, Step7, Tooltip },
  data: () => ({ arrow }),
  computed: {
    ...mapState({
      title: (state) => state.assessmentSetup.assessmentTitle,
      mode: (state) => state.assessmentSetup.mode,
      generalInfo: (state) => state.assessmentSetup.generalInfo,
      publishAvalability: (state) => state.assessmentSetup.publishAvalability,
    }),
    ...mapGetters({
      isImpersonating: "auth/isImpersonating",
      isSeller: "auth/isSeller",
    }),
    assessmentType() {
      return this.generalInfo.surveyType;
    },
    productVersion(){
      return this.generalInfo.productVersion;
    },
  },
  async mounted() {
    await this.continueDraft({ id: this.$route.params.assessmentId });
    await this.getGeneralInfo({ id: this.$route.params.assessmentId });
    await this.getMessages({ id: this.$route.params.assessmentId });
  },
  methods: {
    ...mapActions({
      getGeneralInfo: "assessmentSetup/getGeneralInfo",
      getMessages: "assessmentSetup/getMessages",
      getParticipants: "assessmentSetup/getParticipants",
      continueDraft: 'assessmentSetup/continueDraft',
    }),
    goBack() {
      this.$router.push("/assessments/communication");
    },
    displayVersionForSeller(version) {
      if (version &&  version !== "" && (this.isImpersonating || this.isSeller)) 
        return " (v" + version + ")";
      return "";
    },
  },
};
</script>
